import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header" style={{backgroundColor: "white"}}>
        <img src={logo} alt="logo" />
        <p style={{color: "grey"}}>
          Welcome to JKB Globus, we'll be back in a short while.
        </p>
      </header>
    </div>
  );
}

export default App;
